import React from 'react';
import { PulseLoader } from 'halogenium';
import qs from 'qs';
import { getCountryCodeAndNumberFromPhoneNumber } from "../scheduling-utils";
import Axios from "axios";
import SelectPaymentSystem from './SelectPaymentSystem';
import SelectRequestLocationType from './SelectRequestLocationType';
import {_t} from "../../js/translation";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import "react-multi-email/style.css";
import {Visible} from "../../common/components/visible";
import {BtnBar, Button, Heading, Icon, Text} from "../../app/common/ui-kit";
import QuestionsForm from "../../app/common/component/question/QuestionsForm";
import PhoneField from "../../app/common/component/fields/phone.field";
import _ from "lodash";

class NewEventForm extends React.Component {
    constructor(props) {
        super(props);

        const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        this.eventTypeShowPhone = this.props.eventTypeShowPhone;

        const phoneWithCountry = this.eventTypeShowPhone ? getCountryCodeAndNumberFromPhoneNumber(query.phone) : null;

        this.state = {
            isPhoneNumberValid: true,
            emails: [],
            isShowParticipants: false,
            full_name: query.name ? query.name : '',
            email: query.email ? query.email : '',
            cancellationPolicy: false,
            phone_number: phoneWithCountry ? phoneWithCountry.phone : '',
            phoneCountry: phoneWithCountry ? phoneWithCountry.country : '',
        };

        if (this.state.full_name !== '') {
            this.props.onChange({name: 'full_name', value: this.state.full_name});
        }

        if (this.state.email !== '') {
            this.props.onChange({name: 'email', value: this.state.email});
        }

        if (this.state.phone_number !== '') {
            this.props.onChange({name: 'phone_number', value: this.state.phone_number});
        }

        if (this.props.isClientCanAddParticipants && query.participants) {
            let participants = query.participants.filter((email) => {
                if (isEmail(email)) {
                    return email;
                }
            });
            if (participants.length) {
                this.state.isShowParticipants = true;
                this.state.participants = participants;
                this.props.onChange({name: 'participants', value: participants});
            }
        }

        this.onlyRequestLocationType = false;
        if (this.props.requestLocation) {
            const eventTypeRequestLocationTypes = this.props.eventTypeRequestLocationTypes;
            this.onlyRequestLocationType = eventTypeRequestLocationTypes && eventTypeRequestLocationTypes.length === 1 && eventTypeRequestLocationTypes[0] === 'request';
            if (this.onlyRequestLocationType) {
                this.state.locationType = 'request';
                this.props.onChange({name: 'location_type', value: 'request'});
            }
        }

        this.onFullNameChange = this.onFullNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onChangeCancellationPolicy = this.onChangeCancellationPolicy.bind(this);
        this.onOpenCancellationPolicyPopup = this.onOpenCancellationPolicyPopup.bind(this);
        this.onCloseCancellationPolicyPopup = this.onCloseCancellationPolicyPopup.bind(this);
        this.onAllowCancellationPolicy = this.onAllowCancellationPolicy.bind(this);
        this.onPhoneChange = this.onPhoneChange.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.onAnswerChange = this.onAnswerChange.bind(this);
        this.onAnswerSelectChange = this.onAnswerSelectChange.bind(this);
        this.onPaymentSystemChange = this.onPaymentSystemChange.bind(this);
        this.onRequestLocationTypeChange = this.onRequestLocationTypeChange.bind(this);

        this.isFirstRender = true;
    }

    isFormValid() {
        let valid = true;
        let errors = {
            errors: {
                children: {
                    invitee: {
                        children: {
                            email: {
                                errors: []
                            },
                            fullName: {
                                errors: []
                            },
                            phoneNumber: {
                                errors: []
                            },
                            cancellationPolicy: {
                                errors: []
                            }
                        }
                    },
                    details: {
                        errors: []
                    },
                    participants: {
                        errors: []
                    },
                    location: {
                        errors: []
                    },
                    payment_system: {
                        errors: []
                    },
                    location_type: {
                        errors: []
                    }
                }
            }
        };

        const detailsFieldLimit = 250;
        const requestLocation = this.props.requestLocation || window.requestLocation;

        if ('' == this.props.newEvent.invitee.full_name) {

            errors.errors.children.invitee.children.fullName.errors = [_t('scheduling.errors.full_name')];
            valid = false;
        }

        if (this.props.cancellationPolicy && this.props.cancellationPolicy.enabled && !this.props.newEvent.invitee.cancellationPolicy) {
            errors.errors.children.invitee.children.cancellationPolicy.errors = [_t('scheduling.errors.cancellationPolicy')];
            valid = false;
        }

        if ('' == this.props.newEvent.invitee.email) {
            errors.errors.children.invitee.children.email.errors = [_t('scheduling.errors.email')];
            valid = false;
        }

        if (this.eventTypeShowPhone && this.props.phoneFieldRequired && '' == this.props.newEvent.invitee.phone_number) {
            errors.errors.children.invitee.children.phoneNumber.errors = [_t('scheduling.errors.phone_number')];
            valid = false;
        }

        if (this.props.isPaymentEnabled || window.isPaymentEnabled) {
            if (this.props.simplypayPaymentSystems && this.props.simplypayPaymentSystems.length) {
                if (!this.props.newEvent.payment_system) {
                    errors.errors.children.payment_system.errors = [_t('scheduling.errors.payment_system')];
                    valid = false;
                }
            }
        }

        if (requestLocation) {
            if (!this.props.newEvent.location_type) {
                errors.errors.children.location_type.errors = [_t('Please choose one of the options.')];
                valid = false;
            }
        }

        if (requestLocation && this.props.newEvent.location_type === 'request' && '' == this.props.newEvent.location) {
            if (this.props.newEvent.location.length > 255) {
                errors.errors.children.location.errors = [_t('scheduling.errors.details_too_long_%limit%', {limit: 255})];
            }

            errors.errors.children.location.errors = [_t('scheduling.errors.required_field')];

            valid = false;
        }

        if (this.eventTypeShowPhone && '' != this.props.newEvent.invitee.phone_number && !this.state.isPhoneNumberValid) {
            errors.errors.children.invitee.children.phoneNumber.errors = [_t('scheduling.errors.phone_number_invalid')];
            valid = false;
        }

        if (this.props.newEvent.participants && this.props.newEvent.participants.length > this.props.maxLimitOfParticipantsForClient) {
            errors.errors.children.participants.errors = [_t('scheduling.participants.limit')];
            valid = false;
        }

        this.props.setError(errors);

        return valid;
    }

    addNewEvent(event) {
        event.preventDefault();

        const eventTypeName = window.eventTypeName;
        const isPaymentEnabled = this.props.isPaymentEnabled || window.isPaymentEnabled;

        if (!this.isFormValid()) {
            return;
        }

        if (isPaymentEnabled) {
            if (window.isGtagLoaded && window.isCookiesAccepted) {
                gtag('event', 'invitee_meeting_scheduled', {
                    'event_category': 'simplymeet',
                    'event_label': eventTypeName,
                    'event_callback': this.props.onSubmit(this.props.eventTypeId)
                });

                return;
            }

            this.props.onSubmit(this.props.eventTypeId);

            return;
        }

        if (window.isGtagLoaded && window.isCookiesAccepted) {
            gtag('event', 'invitee_meeting_scheduled', {
                'event_category': 'simplymeet',
                'event_label': eventTypeName,
                'event_callback': this.props.onSubmit(this.props.eventTypeId)
            });

            return;
        }

        this.props.onSubmit(this.props.eventTypeId);
    }

    onFullNameChange(event) {
        const {value} = event.target;

        this.setState({full_name: value});
        this.props.onChange({name: 'full_name', value});
    }

    onEmailChange(event) {
        const {value} = event.target;

        this.setState({email: value});
        this.props.onChange({name: 'email', value});
    }

    onChangeCancellationPolicy(event) {
        const checked = event.target.checked;

        this.setState({cancellationPolicy: checked});
        this.props.onChange({name: 'cancellationPolicy', value: checked ? event.target.value : null });
    }

    onOpenCancellationPolicyPopup() {
        $('.cancellation-policy-popup').show();
        $('body').css({'overflow': 'hidden'});
    }

    onCloseCancellationPolicyPopup() {
        $('.cancellation-policy-popup').hide();
        $('body').css({'overflow': 'unset'});
    }

    onAllowCancellationPolicy() {
        this.setState({cancellationPolicy: true});
        this.props.onChange({name: 'cancellationPolicy', value: this.props.cancellationPolicy.type + '_' + this.props.cancellationPolicy.version });
        $('.cancellation-policy-popup').hide();
        $('body').css({'overflow': 'unset'});
    }

    onParticipantsChange(participants) {
        this.setState({participants: participants});
        this.props.onChange({name: 'participants', value: participants});
    }

    onClickAddParticipants() {
        this.setState({isShowParticipants: true});
    }

    onPaymentSystemChange(event) {
        const {value} = event.target;

        this.setState({paymentSystem: value});
        this.props.onChange({name: 'payment_system', value});
        if (this.props.errors && this.props.errors.hasOwnProperty('errors')) {
            this.props.errors.errors.children.payment_system.errors = [];
        }
    }

    onRequestLocationTypeChange(event) {
        const {value} = event.target;

        this.setState({locationType: value});
        this.props.onChange({name: 'location_type', value});
        if (this.props.errors && this.props.errors.hasOwnProperty('errors')) {
            this.props.errors.errors.children.location_type.errors = [];
        }
    }

    onPhoneChange(isValid, value, countryData, number) {
        if (this.props.errors && this.props.errors.hasOwnProperty('errors')) {
            this.props.errors.errors.children.invitee.children.phoneNumber.errors = [];
        }

        if (value === '') {
            this.props.onChange({name: 'phone_number', value: ''});
            this.setState({isPhoneNumberValid: true, phone_number: ''});

            return;
        }

        this.setState({isPhoneNumberValid: isValid, phone_number: value});
        this.props.onChange({name: 'phone_number', value: number});
    }

    onLocationChange(event) {
        const {value} = event.target;

        this.props.onChange({name: 'location', value});
    }

    onAnswerChange(event) {
        const value = event.target.value;
        const questionId = event.target.getAttribute('data-uuid');

        this.props.onChange({name: 'answers', questionId, value});
    }

    onAnswerSelectChange(questionId, value) {
        this.props.onChange({name: 'answers', questionId, value});
    }

    render() {
        const { emails } = this.state;

        let multipleEmailInput = $('.react-multi-email input[type="text"]');
        if (this.isFirstRender && multipleEmailInput.length) {
            this.isFirstRender = false;

            multipleEmailInput.focusin(function () {
                $('.form-group--participants').addClass('force-focused');
            })
            multipleEmailInput.focusout(function () {
                $('.form-group--participants').removeClass('force-focused');
            })
        }

        const lookup = (callback) => {
            Axios.get('//ipinfo.io/json')
                .then(result => callback(result.data.country))
                .catch((reason) => {
                    console.log(reason);
                    return callback('us');
                });
        };

        const onFlagSelectHandler = (value, countryData, number) => {
            if (this.props.errors && this.props.errors.hasOwnProperty('errors')) {
                this.props.errors.errors.children.invitee.children.phoneNumber.errors = [];
            }

            if (value === '') {
                number = value;
            }

            this.setState({isPhoneNumberValid: true});
            this.props.onChange({name: 'phone_number', value: number});
        };

        const questions = this.props.questions;
        const cancellationPolicy = this.props.cancellationPolicy;
        const simplypayPaymentSystems = this.props.simplypayPaymentSystems;
        const eventTypeRequestLocationTypes = this.props.eventTypeRequestLocationTypes;
        const eventTypeLocation = this.props.eventTypeLocation;
        const requestLocation = this.props.requestLocation;
        const locationLabel = this.props.eventTypeLocationQuestion;
        const isPaymentEnabled = this.props.isPaymentEnabled || window.isPaymentEnabled;
        const isShowPayBtn = isPaymentEnabled && (simplypayPaymentSystems.length > 1 || simplypayPaymentSystems[0].paymentSystem !== 'delay');

        return (
            <div className="event-block--step-item event-block--step-item__form new-event--client-form__wrapper theme_styles--text">
                <h4 className={'title title--h4 step-title'}>{_t('scheduling.meeting_details')}</h4>
                <div className={'new-event--client-form'}>
                    <form onSubmit={this.addNewEvent.bind(this)}>
                    <div className="form-group">
                        <label>{_t('scheduling.full_name')} *</label>
                        <input type="text"
                               value={this.state.full_name}
                               onChange={this.onFullNameChange} name="full_name"
                               className="form-control" placeholder={_t('scheduling.full_name_placeholder')}
                        />
                        {this.props.errors && this.props.errors.hasOwnProperty('errors') ?
                            <small
                                className="form-error">{this.props.errors.errors.children.invitee.children.fullName.errors}</small> : null}
                    </div>
                    <div className="form-group">
                        <label>{_t('scheduling.email_address')} *</label>
                        <input type="email"
                               value={this.state.email}
                               onChange={this.onEmailChange}
                               name="email"
                               className="form-control"
                               placeholder={_t('scheduling.email_address_placeholder')}
                        />
                        {this.props.errors && this.props.errors.hasOwnProperty('errors') ?
                            <small
                                className="form-error">{this.props.errors.errors.children.invitee.children.email.errors}</small> : null}
                    </div>

                    <Visible visible={this.props.isClientCanAddParticipants  && this.state.isShowParticipants}>
                        <div className="form-group form-group--participants">
                            <label>{_t('scheduling.participants')}</label>

                            <ReactMultiEmail
                                className="form-control"
                                name="participants"
                                placeholder={_t('scheduling.participants_placeholder')}
                                emails={this.state.participants}
                                onChange={this.onParticipantsChange.bind(this)}
                                validateEmail={participant => isEmail(participant)}
                                getLabel={(email, index, removeEmail) => {
                                    return (
                                        <div data-tag key={index}>
                                            {email}
                                            <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                        </div>
                                    );
                                }}
                            />
                            <label className='participants--hint'>
                                <i className='participants--hint-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"/></svg>
                                </i>
                                <span className='participants--hint-txt'>{_t('scheduling.participants.help')}</span>
                            </label>

                            {this.props.errors && this.props.errors.hasOwnProperty('errors') ?
                                <small className="form-error">{this.props.errors.errors.children.participants.errors}</small> : null}

                            {this.props.errors && this.props.errors.hasOwnProperty('errors') && this.props.errors.errors.hasOwnProperty('errors') ?
                                <small className="form-error">{this.props.errors.errors.errors}</small> : null}
                        </div>
                    </Visible>

                    {this.eventTypeShowPhone ?
                        <div className="form-group">
                            <label>{_t('scheduling.phone_number')} {this.props.phoneFieldRequired ? '*' : null}</label>
                            <PhoneField
                                skipFormGroup={true}
                                isRtl={window.isRtl}
                                geoIpLookup={lookup}
                                name={'phone_number'}
                                separateDialCode={true}
                                onChange={this.onPhoneChange}
                                onSelectFlag={onFlagSelectHandler}
                                value={this.state.phone_number}
                                country={this.state.phoneCountry === '' ? 'auto' : this.state.phoneCountry}
                            />
                            {this.props.errors && this.props.errors.hasOwnProperty('errors') || (this.props.errors && this.props.errors.hasOwnProperty('errors') && !this.state.isPhoneNumberValid) ? <small className="form-error">{this.props.errors.errors.children.invitee.children.phoneNumber.errors}</small> : null}
                        </div>
                        : null}

                    {requestLocation && !this.onlyRequestLocationType ?
                        <div className="form-group">
                            <label>{locationLabel} *</label>
                            <SelectRequestLocationType
                                eventTypeRequestLocationTypes={eventTypeRequestLocationTypes}
                                eventTypeLocation={eventTypeLocation}
                                onChange={this.onRequestLocationTypeChange}
                                errors={this.props.errors}
                            />
                        </div>
                        : null}

                    {requestLocation && this.state.locationType === 'request' ?
                        <div className="form-group">
                            {this.onlyRequestLocationType ?
                                <label>
                                    {locationLabel} *
                                </label>
                                : null}
                            <textarea onChange={this.onLocationChange} name="location" className="form-control"/>
                            {this.props.errors && this.props.errors.hasOwnProperty('errors') ?
                                <small
                                    className="form-error">{this.props.errors.errors.children.location.errors}</small> : null}
                        </div>
                        : null}

                    <QuestionsForm
                        questions={questions}
                        onChange={this.onAnswerChange}
                        onSelectChange={this.onAnswerSelectChange}
                        errors={this.props.errors}
                    />

                    {cancellationPolicy && cancellationPolicy.enabled ?
                        <div className="form-group">
                            <div className={'one-row'}>
                                <input
                                    type="checkbox"
                                    checked={this.state.cancellationPolicy}
                                    value={cancellationPolicy.type + '_' + cancellationPolicy.version}
                                    onChange={this.onChangeCancellationPolicy}
                                    name={'cancellationPolicy'}
                                />
                                <span className="custom-checkbox"><i className="ico"></i></span>
                                <label htmlFor={'cancellationPolicy'}>
                                    <span id={"cancellation-policy-popup-open"}
                                          className={'theme_styles--link'}
                                          onClick={this.onOpenCancellationPolicyPopup}
                                    >{cancellationPolicy.label} *</span>
                                </label>
                            </div>
                            <div className={'cancellation-policy-popup'} onClick={this.onCloseCancellationPolicyPopup}>
                                <div className={"cancellation-policy-popup-content"} onClick={(event) => (event.preventDefault(), event.stopPropagation())}>
                                    <div className="cancellation-policy-popup--header">
                                        <Heading type={Heading.TYPE.H3} title={cancellationPolicy.label}/>
                                        <Button template={Button.TEMPLATE.DANGER} icon={Icon.ICON.CANCEL} onClick={this.onCloseCancellationPolicyPopup}/>
                                    </div>
                                    <div className="cancellation-policy-popup--body">
                                        <div className="scroll-box">
                                            <Text caption={cancellationPolicy.text}/>
                                        </div>
                                    </div>
                                    <div className="cancellation-policy-popup--footer">
                                        <BtnBar content={BtnBar.CONTENT.RIGHT}>
                                            <button className={"btn btn--primary " + (!this.props.isLoading ? 'theme_styles--button' : '')} type="button" style={this.props.isLoading ? {backgroundColor: '#d9d9d9'} : {}} onClick={this.onAllowCancellationPolicy}>
                                                {_t('I agree')}
                                            </button>
                                        </BtnBar>
                                    </div>
                                </div>
                            </div>
                            {this.props.errors && this.props.errors.hasOwnProperty('errors') ?
                                <small className="form-error">{this.props.errors.errors.children.invitee.children.cancellationPolicy.errors}</small>
                            : null}
                        </div>
                    : null}

                    {isPaymentEnabled ?
                        <div className="form-group">
                            <SelectPaymentSystem
                                allowedPaymentSystems={simplypayPaymentSystems}
                                onChange={this.onPaymentSystemChange}
                                errors={this.props.errors}
                            />
                        </div>
                    : null}

                    {this.props.errors && this.props.errors.hasOwnProperty('code') && this.props.errors.code == 409 ?
                        <div className="form-group">
                            <small className="form-error">{_t('scheduling.errors.busy_invalid')}</small>
                        </div> : null
                    }

                    <div className="btn-bar client-form__btn-bar">
                        <div className="btn-bar--row">
                            <Visible visible={this.props.isClientCanAddParticipants && !this.state.isShowParticipants}>
                                <input
                                    type="submit"
                                    className="btn btn--link add-participants theme_styles--link"
                                    onClick={this.onClickAddParticipants.bind(this)}
                                    value={_t('scheduling.participants.add')}
                                />
                            </Visible>

                            {this.props.isLoading
                                ? <input
                                    type="submit" disabled={true}
                                    style={{backgroundColor: '#d9d9d9'}}
                                    className="btn btn--primary"
                                    value={_t('scheduling.wait')}
                                />
                                : <input
                                    type="submit"
                                    className="btn btn--primary theme_styles--button"
                                    value={_t(isShowPayBtn ? 'scheduling.schedule_meeting_with_payment' : 'scheduling.schedule_meeting')}
                                />
                            }
                        </div>
                        {this.props.isLoading ? <PulseLoader color="#5bb9f4" size="16px" margin="4px"/> : null}
                    </div>
                </form>
                </div>
            </div>
        );
    }
}

export default NewEventForm;
